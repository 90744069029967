<template>
    <div>
        <attach-people-to-company
            v-if="resourceData"
            id="attach-people-to-company-modal"
            :resourceData="resourceData"
            :resourceType="resourceType"
            :recordEdit="recordEdit"
            @hide="recordEdit = null"
            v-on="$listeners"
        >
        </attach-people-to-company>
        <b-card>
            <datatable-header
                :title="$tc('modules.people.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                :hideFilterButton="true"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'attach-people-to-company-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "modules.people.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @filterUpdated="onFilterUpdate"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>
            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: false,
                }"
                :rows="resourceData.people"
                :rtl="direction"
                :searchOptions="{
                    enabled: false,
                    externalQuery: searchTerm,
                }"
                :totalRows="resourceData.people.length"
                theme="my-theme"
            >
                <template #table-row="props">
                    <span v-if="props.column.field === 'isPrimaryContact'">
                        <b-badge
                            :variant="
                                isPrimaryContactVariant(
                                    props.row.isPrimaryContact
                                        ? props.row.isPrimaryContact
                                        : false
                                )
                            "
                        >
                            {{
                                props.row.isPrimaryContact
                                    ? $t("common.primary")
                                    : $t("common.secondary")
                            }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'status'">
                        <b-badge
                            v-if="props.row.status"
                            :variant="
                                statusVariant(props.row.status.description)
                            "
                        >
                            {{ props.row.status.description }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'emails'">
                        <span v-for="email in props.row.emails" :key="email.id">
                            {{ email.address }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'phones'">
                        <span v-for="phone in props.row.phones" :key="phone.id">
                            {{ phone.number }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'actions'">
                        <span class="d-inline">
                            <feather-icon
                                v-b-modal.attach-people-to-company-modal
                                icon="Edit2Icon"
                                size="20"
                                style="cursor: pointer"
                                @click="openForm(props.row)"
                            />
                        </span>
                        <span class="d-inline pl-1">
                            <feather-icon
                                icon="Trash2Icon"
                                size="20"
                                style="cursor: pointer"
                                @click="onDeleteClick(props.row)"
                            />
                        </span>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BCard, BBadge, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import Api from "@/services/api/index"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import AttachPeopleToCompany from "./AttachPeopleToCompany.vue"

export default {
    components: {
        BButton,
        DatatableHeader,
        BCard,
        BBadge,
        Datatable,
        DatatableFilter,
        AttachPeopleToCompany,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            recordEdit: null,
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            columns: [
                {
                    label: this.$t("modules.people.primaryContact"),
                    field: "isPrimaryContact",
                },
                {
                    label: this.$t("common.crud.name"),
                    field: "name",
                },
                {
                    label: this.$t("common.status"),
                    field: "status",
                },
                {
                    label: this.$t("modules.people.jobDescription"),
                    field: "jobDescription",
                },
                {
                    label: this.$tc("common.phone.description", 1),
                    field: "phones",
                },
                {
                    label: this.$t("modules.people.extension"),
                    field: "extension",
                },

                {
                    label: this.$tc("common.email.description", 2),
                    field: "emails",
                },
                {
                    label: this.$t("modules.people.birthdate"),
                    field: "birthDate",
                    formatFn: this.formatDate,
                },
                {
                    label: this.$t("common.crud.actions"),
                    field: "actions",
                },
            ],
            searchTerm: "",
            availableDatatableFilters: {},
            apiFilter: null,
            showCreateComponent: false,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },

        isPrimaryContactVariant() {
            const isPrimaryContactColor = {
                true: "light-success",
                false: "light-warning",
            }

            return isPrimaryContact =>
                isPrimaryContactColor[isPrimaryContact]
        },

        statusVariant() {
            const statusColor = {
                Ativo: "light-primary",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },
    created() {
        this.isLoading = false
    },
    methods: {
        openForm(value) {
            this.recordEdit = value
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            // this.getPeople()
        },
        formatDate(date) {
            if (date == null) return

            // eslint-disable-next-line consistent-return
            return this.$d(new Date(date), "date")
        },

        async onDeleteClick(companyPerson) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("modules.people.confirmDetachResource", {
                    personName: companyPerson.name,
                    companyName: this.resourceData.name,
                }),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.destroy("companies-people", companyPerson.id)

                this.$emit("deleteCompanyPerson", companyPerson)

                this.$toast(toastMessages.getSuccessMessage())

                this.$emit("deleteCompanyPerson", companyPerson)
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
