var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('b-card',{},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('b-col',{staticClass:"mr-2",attrs:{"cols":"6","md":"3"}},[_c('b-avatar',{attrs:{"variant":"info","text":_vm.getNameInitials,"size":"10rem"}})],1),_c('b-col',{staticClass:"mr-2",attrs:{"cols":"6","md":"8"}},[_c('b-row',[(_vm.companyData.name)?_c('h4',{staticClass:"text-truncate d-block"},[_vm._v(" "+_vm._s(_vm.companyData.name)+" ")]):_vm._e()]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-row',[_c('p',{staticClass:"font-medium-2"},[_vm._v(" "+_vm._s(_vm.$t("modules.people.profile") + " " + _vm.$t("common.status"))+" ")])]),_c('b-row',{},[(_vm.companyData.status)?_c('b-badge',{staticClass:"mr-1",staticStyle:{"height":"1.5rem"},attrs:{"variant":_vm.statusVariant(
                                                _vm.companyData.status
                                                    .description
                                            )}},[_vm._v(" "+_vm._s(_vm.companyData.status.description)+" ")]):_vm._e(),(_vm.companyData.id)?_c('p',{staticClass:"text-truncate"},[_vm._v(" ID: "+_vm._s(_vm.companyData.id)+" ")]):_vm._e()],1),_c('b-row',[(_vm.companyData.editor)?_c('p',[_vm._v(" "+_vm._s(_vm.$t( "common.crud.lastUpdatedAtBy", { time: _vm.$d( new Date( _vm.companyData.updatedAt ), "dateTime" ), user: _vm.companyData.editor .name, } ))+" ")]):_vm._e()])],1)],1)],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[(_vm.$can('edit companies'))?_c('b-button',{staticClass:"mr-1",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit(
                                    'openEditResourceTab',
                                    'companiesEditTabForm'
                                )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.editResource", { resource: _vm.$tc( "modules.companies.description", 1 ), }))+" ")]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('formulateForm',{attrs:{"name":"headerForm"},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('b-row',{staticClass:"d-block mb-1"},[_c('div',[_c('feather-icon',{attrs:{"icon":"StarIcon","size":"18"}}),_c('span',{staticClass:"ml-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t("common.relationships")))])])],1),_c('div',{staticClass:"mt-1"},[_c('formulateInput',{attrs:{"name":"relationships","type":"vue-select","multiple":"","validation":"optional","options":_vm.relationshipsOptions.map(function (el) { return ({
                                        value: el.id,
                                        label: el.description,
                                    }); })},on:{"input":_vm.handleRelationshipsUpdate,"update:input":function($event){_vm.formData.relationships = $event}}})],1)]),_c('b-row',{staticClass:"d-block"},[_c('div',[_c('feather-icon',{attrs:{"icon":"AwardIcon","size":"18"}}),_c('span',{staticClass:"ml-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t("common.classifications")))])])],1),_c('div',{staticClass:"mt-1"},[_c('formulateInput',{attrs:{"name":"classifications","type":"vue-select","multiple":"","validation":"optional","options":_vm.classificationsOptions.map(function (el) { return ({
                                        value: el.id,
                                        label: el.description,
                                    }); })},on:{"input":_vm.handleClassificationsUpdate,"update:input":function($event){_vm.formData.classifications = $event}}})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }