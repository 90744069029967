<template>
    <b-modal
        v-if="$can('update', 'companies')"
        v-bind="$attrs"
        ref="attach-people-to-company"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('modules.people.description', 1).toLowerCase()
        "
        hideFooter
        size="lg"
        @hide="$emit('hide')"
        @show="setAttributesDefaultValues"
    >
        <FormulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="emailForm"
            @submit="handleSubmit"
        >
            <b-row>
                <b-col cols="12" md="6">
                    <formulateInput
                        :label="$tc('modules.people.description', 1)"
                        :placeholder="$t('common.crud.select')"
                        name="personId"
                        type="vue-select-async"
                        :apiData="{
                            resource: 'people',
                            fields: { people: 'name' },
                            fieldsMapping: {
                                label: 'name',
                                value: 'id',
                            },
                        }"
                        :options="people"
                        validation="required"
                        @update:input="formData.personId = $event"
                        @update:options="people = $event"
                    >
                    </formulateInput>
                </b-col>

                <b-col cols="12" md="6">
                    <FormulateInput
                        :label="$t('modules.people.jobDescription')"
                        name="jobDescription"
                        :placeholder="$t('modules.people.jobDescription')"
                        type="text"
                        validation="optional|max:255,length"
                    />
                </b-col>

                <b-col cols="12" md="3">
                    <FormulateInput
                        :label="$t('modules.people.primaryContact')"
                        name="isPrimaryContact"
                        :placeholder="$t('common.crud.select')"
                        type="select"
                        :options="{
                            true: $t('common.yes'),
                            false: $t('common.no'),
                        }"
                    />
                </b-col>

                <b-col cols="12" md="3">
                    <FormulateInput
                        :label="$t('modules.people.extension')"
                        name="extension"
                        :placeholder="$t('modules.people.extension')"
                        type="text"
                        validation="optional|extension"
                        maxlength="4"
                        :validationMessages="{
                            extension: $t(
                                'common.crud.errors.invalidExtension'
                            ),
                        }"
                    />
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" md="6">
                    <FormulateInput
                        :label="$t('modules.people.assistent')"
                        name="assistent"
                        :placeholder="$t('modules.people.assistent')"
                        type="text"
                        validation="optional|max:255,length"
                    />
                </b-col>
                <b-col cols="12" md="3">
                    <FormulateInput
                        :label="$t('modules.people.assistentPhone')"
                        name="assistentPhone"
                        :placeholder="$t('modules.people.assistentPhone')"
                        type="text"
                        maxlength="11"
                        validation="optional|phone"
                        :validationMessages="{
                            phone: $t('common.crud.errors.invalidPhone'),
                        }"
                    />
                </b-col>
                <b-col cols="12" md="3">
                    <FormulateInput
                        :label="$t('modules.people.assistentExtension')"
                        name="assistentExtension"
                        :placeholder="$t('modules.people.assistentExtension')"
                        type="text"
                        maxlength="4"
                        validation="optional|extension"
                        :validationMessages="{
                            extension: $t(
                                'common.crud.errors.invalidExtension'
                            ),
                        }"
                    />
                </b-col>
                <b-col cols="12" md="6">
                    <FormulateInput
                        :label="$t('modules.people.assistentEmail')"
                        name="assistentEmail"
                        :placeholder="$t('modules.people.assistentEmail')"
                        type="text"
                        validation="optional|max:255,length|email"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-row class="mt-3">
                    <b-col class="d-flex justify-content-center">
                        <FormulateErrors />
                    </b-col>
                </b-row>
            </b-row>

            <b-row class="justify-content-center align-self-center">
                <FormulateInput
                    v-if="!recordEdit"
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $tc('common.crud.attaching', 1)
                            : $tc('common.crud.attaching', 2)
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
                <FormulateInput
                    v-else
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />

                <FormulateInput
                    :label="$t('common.crud.close')"
                    inputClass="btn btn-danger"
                    type="button"
                    class="pl-2"
                    @click="hideModal"
                />
            </b-row>
        </FormulateForm>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BModal,
        BRow,
        BCol,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        recordEdit: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            formData: {
                jobDescription: "",
                extension: "",
                isPrimaryContact: "",
                assistent: "",
                assistentPhone: "",
                assistentExtension: "",
                assistentEmail: "",
                personId: "",
            },
            people: [],
        }
    },
    methods: {
        setAttributesDefaultValues() {
            if (this.recordEdit) {
                this.people = [
                    {
                        label: this.recordEdit.name,
                        value: this.recordEdit.personId.toString(),
                    },
                ]
                this.formData.jobDescription = this.recordEdit.jobDescription
                this.formData.extension = this.recordEdit.extension
                this.formData.isPrimaryContact = this.recordEdit
                    .isPrimaryContact
                    ? this.recordEdit.isPrimaryContact.toString()
                    : "false"
                this.formData.assistent = this.recordEdit.assistent
                this.formData.assistentPhone = this.recordEdit.assistentPhone
                this.formData.assistentExtension = this.recordEdit.assistentExtension
                this.formData.assistentEmail = this.recordEdit.assistentEmail
                this.formData.personId = this.recordEdit.personId.toString()
            } else {
                this.formData.jobDescription = ""
                this.formData.extension = ""
                this.formData.isPrimaryContact = false
                this.formData.assistent = ""
                this.formData.assistentPhone = ""
                this.formData.assistentExtension = ""
                this.formData.assistentEmail = ""
                this.formData.personId = ""
                this.people = []
            }
        },
        async handleSubmit() {
            let response = null
            try {
                if (this.recordEdit) {
                    response = await Api.update("companies-people", {
                        id: this.recordEdit.id,
                        companyId: parseInt(this.resourceData.id, 10),
                        personId: parseInt(this.formData.personId, 10),
                        jobDescription: this.formData.jobDescription,
                        extension: this.formData.extension,
                        isPrimaryContact:
                            this.formData.isPrimaryContact === "true",
                        assistent: this.formData.assistent,
                        assistentPhone: this.formData.assistentPhone,
                        assistentExtension: this.formData.assistentExtension,
                        assistentEmail: this.formData.assistentEmail,
                    })
                } else {
                    response = await Api.create("companies-people", {
                        companyId: parseInt(this.resourceData.id, 10),
                        personId: parseInt(this.formData.personId, 10),
                        jobDescription: this.formData.jobDescription,
                        extension: this.formData.extension,
                        isPrimaryContact:
                            this.formData.isPrimaryContact === "true",
                        assistent: this.formData.assistent,
                        assistentPhone: this.formData.assistentPhone,
                        assistentExtension: this.formData.assistentExtension,
                        assistentEmail: this.formData.assistentEmail,
                    })
                }

                if (this.recordEdit)
                    this.$emit("deleteCompanyPerson", this.recordEdit)

                this.$emit("attachCompanyPerson", response.data)

                this.$toast(toastMessages.getSuccessMessage())

                this.$refs["attach-people-to-company"].hide()
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        showModal() {
            this.setAttributesDefaultValues()
            this.$refs["attach-people-to-company"].show()
        },
        hideModal() {
            this.$refs["attach-people-to-company"].hide()
        },
    },
}
</script>

<style scoped></style>
