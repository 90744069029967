<template>
    <b-card class="pb-0 mb-0 pr-0 pl-0">
        <div class="d-block">
            <feather-icon icon="UsersIcon" size="28" />
            <h4 class="d-inline">
                {{ $t("modules.companies.mainPeople") }}
            </h4>
            <a
                v-b-modal.attach-people-to-company-modal
                class="under-blue-text"
                @click="resourceEdited = null"
            >
                + {{ $tc("common.crud.attaching", 2) }}
            </a>
        </div>
        <b-row v-if="resourceData" class="mt-1">
            <b-col
                v-for="(people, index) in resourceData.people.filter(
                    (el) => el.isPrimaryContact === true
                )"
                :key="index"
                cols="12"
                class="mb-1"
                @mouseover="showControls(index, 'companies-people')"
            >
                <div class="d-inline">
                    <feather-icon icon="UserIcon" size="18"> </feather-icon>
                    <a
                        class="d-inline under-blue-text ml-1"
                        @click="
                            $router.push({
                                name: 'view-people',
                                params: { id: people.personId },
                            })
                        "
                        @click.middle="openNewTab(people)"
                    >
                        {{ people.name }}
                    </a>
                </div>

                <feather-icon
                    v-if="
                        selected.index === index &&
                        selected.resource === 'companies-people' &&
                        $can('update', 'companies')
                    "
                    icon="Trash2Icon"
                    size="15"
                    class="float-right under-blue-text d-inline align-middle"
                    style="margin-right: 0.5rem"
                    @click="deleteCompanyPerson(people)"
                ></feather-icon>
                <feather-icon
                    v-if="
                        selected.index === index &&
                        selected.resource === 'companies-people' &&
                        $can('update', 'companies')
                    "
                    icon="Edit2Icon"
                    size="15"
                    class="float-right under-blue-text d-inline align-middle mr-1"
                    @click="$emit('editCompanyPeople', people)"
                ></feather-icon>

                <div class="">
                    <feather-icon icon="BriefcaseIcon" size="18">
                    </feather-icon>
                    <p class="d-inline">
                        {{ people.jobDescription }}
                    </p>
                </div>
            </b-col>

            <b-col cols="12">
                <a
                    class="a-link float-right text-right"
                    @click="$emit('updateActiveTab', 7)"
                >
                    <p class="under-blue-text d-inline">
                        {{ $t("common.crud.seeAll") }}
                    </p>
                </a>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BRow,
        BCol,
        BCard,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            createPersonState: false,
            selected: "",
            resourceEdited: null,
        }
    },
    methods: {
        showControls(index, resource) {
            this.selected = { index, resource }
        },
        hideControls() {
            this.selected = ""
        },
        openNewTab(person) {
            const routeData = this.$router.resolve({
                name: "view-people",
                params: { id: person.personId },
            })
            window.open(routeData.href, "_blank")
        },

        async deletePhone(phone) {
            try {
                await Api.one(this.resourceType, this.resourceData.id)
                    .relationships("phones")
                    .destroy([
                        {
                            type: "phones",
                            id: phone.id,
                        },
                    ])

                this.$emit("deletePhone", phone)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        async deleteCompanyPerson(companyPerson) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("modules.people.confirmDetachResource", {
                    personName: companyPerson.name,
                    companyName: this.resourceData.name,
                }),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.destroy("companies-people", companyPerson.id)

                this.$emit("deleteCompanyPerson", companyPerson)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped></style>
