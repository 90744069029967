<template>
    <div>
        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>
        <div v-else>
            <!-- Alert: No item found -->
            <b-alert variant="danger" :show="companyData === undefined">
                <h4 class="alert-heading">
                    {{
                        $t("common.crud.errors.fetch", {
                            resource: $tc("modules.companies.description", 1),
                        })
                    }}
                </h4>
                <div class="alert-body">
                    {{
                        $t("common.crud.errors.notfound", {
                            resource: $tc("modules.companies.description", 1),
                        })
                    }}
                    <b-link class="alert-link" :to="{ name: 'companies' }">
                        {{
                            $t("common.crud.errors.list", {
                                resource: $tc(
                                    "modules.companies.description",
                                    1
                                ),
                            })
                        }}
                    </b-link>
                    {{
                        $t("common.crud.errors.resource", {
                            resource: $tc("modules.companies.description", 2),
                        })
                    }}
                </div>
            </b-alert>

            <b-row>
                <b-col cols="12" md="12">
                    <companies-view-profile-image
                        v-if="companyData"
                        :companyData="companyData"
                        :relationshipsOptions="relationshipsOptions"
                        :classificationsOptions="classificationsOptions"
                        @openEditResourceTab="activeTab = 5"
                    >
                    </companies-view-profile-image>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="3">
                    <contact-card
                        v-if="companyData"
                        resourceType="companies"
                        :resourceData="companyData"
                        :phoneClassificationsOptions="
                            phoneClassificationsOptions
                        "
                        @deletePhone="
                            companyData.phones = companyData.phones.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachPhone="getRelatedPhones"
                        @deleteEmail="
                            companyData.emails = companyData.emails.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachEmail="getRelatedEmails"
                    >
                    </contact-card>

                    <main-address-card-view
                        v-if="companyData"
                        class="pb-0 mb-0 mt-1"
                        resourceType="companies"
                        :resourceData="companyData"
                        @updateActiveTab="
                            (value) => {
                                activeTab = value;
                            }
                        "
                    >
                    </main-address-card-view>

                    <main-people-at-company-card
                        v-if="companyData"
                        class="mt-1"
                        resourceType="companies"
                        :resourceData="companyData"
                        @updateActiveTab="
                            (value) => {
                                activeTab = value;
                            }
                        "
                        @editCompanyPeople="openEditCompanyPerson"
                    >
                    </main-people-at-company-card>
                </b-col>

                <b-col cols="12" md="9">
                    <b-tabs
                        v-model="activeTab"
                        pills
                        navClass="nav-pill-primary"
                        navWrapperClass="submenu"
                        class="pill-component"
                    >
                        <b-tab>
                            <template #title>
                                <feather-icon
                                    icon="PieChartIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.general") }}
                                </span>
                            </template>
                            <b-tabs
                                pills
                                navClass="nav-pill-primary"
                                navWrapperClass="submenu"
                                class="pill-component"
                            >
                                <b-tab :title="$t('common.tasks')" disabled>
                                    <!--                                    <TasksList-->
                                    <!--                                        v-if="companyData"-->
                                    <!--                                        :resourceData="companyData"-->
                                    <!--                                        resourceType="companies"-->
                                    <!--                                    >-->
                                    <!--                                    </TasksList>-->
                                </b-tab>
                                <b-tab :title="$t('common.notes')" active>
                                    <NotesList
                                        v-if="companyData"
                                        :resourceData="companyData"
                                        resourceType="companies"
                                        @attachNote="attachNote($event)"
                                    >
                                    </NotesList>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.attachments')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.attachments')"
                                    ></b-card>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.operations')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.operations')"
                                    ></b-card>
                                </b-tab>
                            </b-tabs>
                        </b-tab>

                        <b-tab class="pr-1" disabled>
                            <template #title>
                                <feather-icon
                                    icon="DollarSignIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.financialDonations") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                    icon="PackageIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.materialDonations") }}
                                </span>
                            </template>
                            <material-donation-list
                                v-if="companyData.materialDonations"
                                :donationContact="companyData"
                                :donationContactIsCompany="true"
                                :materialDonationList="
                                    companyData.materialDonations
                                "
                                @getRelatedMaterialDonations="
                                    getMaterialDonationRelationship
                                "
                            >
                            </material-donation-list>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="ListIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.recurrenceList") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="ClipboardIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.volunteerDetails") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit companies')"
                            card
                            :titleItemClass="activeTab !== 5 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="UserIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("modules.people.profile") }}
                                </span>
                            </template>
                            <companies-edit
                                v-if="companyData"
                                :companyData="companyData"
                                :classificationsOptions="classificationsOptions"
                                :relationshipsOptions="relationshipsOptions"
                                :originsOptions="originsOptions"
                                :businessSegmentsOptions="
                                    businessSegmentsOptions
                                "
                                :statusOptions="statusOptions"
                                :inactivationReasonOptions="
                                    inactivationReasonOptions
                                "
                                :newslettersOptions="newslettersOptions"
                                :correspondencesOptions="correspondencesOptions"
                                @closeEditForm="activeTab = 1"
                                @update:company="updateCompanyData($event)"
                            >
                            </companies-edit>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit companies')"
                            card
                            :titleItemClass="activeTab !== 6 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="MapPinIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $tc("common.address.description", 2) }}
                                </span>
                            </template>
                            <addresses-list
                                v-if="companyData"
                                :resourceData="companyData"
                                resourceType="companies"
                                :addressClassificationsOptions="
                                    addressClassificationsOptions
                                "
                                @deleteAddress="
                                    companyData.addresses = companyData.addresses.filter(
                                        (el) => el.id !== $event.id
                                    )
                                "
                                @attachAddress="getRelatedAddresses"
                            >
                            </addresses-list>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit companies')"
                            card
                            :titleItemClass="activeTab !== 7 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="UsersIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $tc("modules.people.description", 2) }}
                                </span>
                            </template>
                            <main-people-at-company-list
                                v-if="companyData"
                                ref="companyPeopleList"
                                :resourceData="companyData"
                                resourceType="companies"
                                @attachCompanyPerson="
                                    attachCompanyPersonHandler
                                "
                                @deleteCompanyPerson="
                                    companyData.people = companyData.people.filter(
                                        (el) => el.id !== $event.id
                                    )
                                "
                            >
                            </main-people-at-company-list>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BLink,
    // BButton,
    // BButtonGroup,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BSpinner,
} from "bootstrap-vue"
import router from "@/router"
import Api from "@/services/api"
import ContactCard from "@/views/components/utilis-spa/contacts/ContactCard.vue"
import AddressesList from "@/views/components/utilis-spa/address/AddressesList.vue"
import MainAddressCardView from "@/views/components/utilis-spa/address/MainAddressCardView.vue"
import _ from "lodash"
import MaterialDonationList from "@/views/app/material-donations/MaterialDonationList.vue"
import MainPeopleAtCompanyCard from "./MainPeopleAtCompanyCard.vue"
import MainPeopleAtCompanyList from "./MainPeopleAtCompanyList.vue"
import CompaniesViewProfileImage from "./CompaniesViewProfileImage.vue"
import NotesList from "../../notes/notes-list/NotesList.vue"
import CompaniesEdit from "../companies-edit/CompaniesEdit.vue"

export default {
    components: {
        CompaniesViewProfileImage,
        MaterialDonationList,
        BAlert,
        BLink,
        BRow,
        BCol,
        // BButton,
        // BButtonGroup,
        // CompaniesViewInfo,
        // TasksList,
        NotesList,
        BTab,
        BTabs,
        BCard,
        BSpinner,
        AddressesList,
        CompaniesEdit,
        ContactCard,
        MainAddressCardView,
        MainPeopleAtCompanyCard,
        MainPeopleAtCompanyList,
    },

    data() {
        return {
            classificationsOptions: [],
            relationshipsOptions: [],
            newslettersOptions: [],
            correspondenceOptions: [],
            inactivationReasonOptions: [],
            originsOptions: [],
            businessSegmentsOptions: [],
            correspondencesOptions: [],
            gendersOptions: [],
            salutationsOptions: [],
            scholaritiesOptions: [],
            organizationsContactsOptions: [],
            statusOptions: [],
            companyPeople: [],
            phoneClassificationsOptions: [],
            addressClassificationsOptions: [],
            activeTab: 0,
            companyData: null,
            loading: true,
        }
    },
    watch: {
        $route(to, from) {
            // Watching route to reload resource data when redirecting to the same route name
            if (to.name === from.name) {
                this.loading = true
                this.companyData = null
                this.getCompanyData(to.params.id)
            }
        },
    },
    async mounted() {
        const systemRecords = await Promise.all([
            this.getClassifications(),
            this.getRelationships(),
            this.getCorrespondencesOptions(),
            this.getNewslettersOptions(),
            this.getOrigins(),
            this.getStatus(),
            this.getBusinessSegmentsOptions(),
            this.getPhoneClassificationsOptions(),
            this.getPeople(router.currentRoute.params.id),
            this.getGenders(),
            this.getSalutationsOptions(),
            this.getSholaritiesOptions(),
            this.getOrganizationsContactsOptions(),
            this.getAddressClassificationsOptions(),
            this.getInactivationReasons(),
            this.getCompanyData(router.currentRoute.params.id),
        ])

        this.classificationsOptions = systemRecords[0].data
        this.relationshipsOptions = systemRecords[1].data
        this.correspondencesOptions = systemRecords[2].data
        this.newslettersOptions = systemRecords[3].data
        this.originsOptions = systemRecords[4].data
        this.statusOptions = systemRecords[5].data
        this.businessSegmentsOptions = systemRecords[6].data
        this.phoneClassificationsOptions = systemRecords[7].data
        this.companyPeople = systemRecords[8].data
        this.gendersOptions = systemRecords[9].data
        this.salutationsOptions = systemRecords[10].data
        this.scholaritiesOptions = systemRecords[11].data
        this.organizationsContactsOptions = systemRecords[12].data
        this.addressClassificationsOptions = systemRecords[13].data
        this.inactivationReasonOptions = systemRecords[14].data

        // Merges people and companiesPeople data
        this.companyData.people = this.companyData.people.map(person => {
            const cPeople = this.companyPeople.find(
                cpPerson => parseInt(person.id, 10) === cpPerson.personId
            )
            return { ...person, ...cPeople }
        }, this)

        this.companyData.materialDonations = this.companyData.materialDonations.map(
            donation => ({
                ...donation,
                contact: {
                    ...donation.contact,
                    name:
                        donation.contact && donation.contact.name
                            ? donation.contact.name
                            : this.companyData.name,
                },
            })
        )
        const donationsIdFilter = this.companyData.materialDonations
            .map(donation => donation.id)
            .join(",")

        const materialDonationItemResponse = await Api.findAll(
            "material-donation-items",
            {
                filter: {
                    materialDonationId: donationsIdFilter,
                },
                // SPARSE FIELDSETS
                fields: {
                    "material-donation-items":
                        "productId,materialDonationId,quantity,unitValue,totalValue",
                },
            }
        )

        this.companyData.materialDonations = this.companyData.materialDonations.map(
            donation => {
                const products = donation.products.map(product => {
                    const item = materialDonationItemResponse.data.find(
                        materialDonationItem =>
                            parseInt(product.id, 10) ===
                            materialDonationItem.productId
                    )
                    return { ...product, ...item }
                })
                return { ...donation, ...{ products } }
            },
            this
        )
    },
    destroyed() {
        this.companyData = null
    },
    methods: {
        async attachCompanyPersonHandler() {
            if (!this.companyData) return
            /*
             *  API calls made to simplify the code, avoiding state management problems
             *  [GET] companies/relationships/people
             *  [GET] companies-people
             * */

            const response = await Promise.all([
                Api.one("companies", router.currentRoute.params.id)
                    .all("people")
                    .get({ include: "status,emails,phones" }),
                this.getPeople(router.currentRoute.params.id),
            ])

            // Merges people and companiesPeople data
            this.companyData.people = response[0].data.map(person => {
                const cPeople = response[1].data.find(
                    cpPerson => parseInt(person.id, 10) === cpPerson.personId
                )
                return { ...person, ...cPeople }
            }, this)
        },
        updateCompanyData(company) {
            this.companyData = company
            this.attachCompanyPersonHandler()
        },
        async getMaterialDonationRelationship() {
            const donationResponse = await Api.one(
                "companies",
                this.companyData.id
            )
                .all("material-donations")
                .get({
                    include:
                        "action,status,contact,products,withdrawalAddress,editor,deliveryLocation," +
                        "products.category,products.unitOfMeasurement",
                })

            const donationsIdFilter = donationResponse.data
                .map(donation => donation.id)
                .join(",")

            const materialDonationItemResponse = await Api.findAll(
                "material-donation-items",
                {
                    filter: {
                        materialDonationId: donationsIdFilter,
                    },
                    // SPARSE FIELDSETS
                    fields: {
                        "material-donation-items":
                            "productId,materialDonationId,quantity,unitValue,totalValue",
                    },
                }
            )

            const materialDonationItems = donationResponse.data.map(
                donation => {
                    const products = donation.products.map(product => {
                        const item = materialDonationItemResponse.data.find(
                            materialDonationItem =>
                                parseInt(product.id, 10) ===
                                materialDonationItem.productId
                        )
                        const index = materialDonationItemResponse.data.indexOf(
                            item
                        )
                        materialDonationItemResponse.data.splice(index, 1)
                        return { ...product, ...item }
                    })
                    return { ...donation, ...{ products } }
                },
                this
            )
            this.companyData = {
                ...this.companyData,
                materialDonations: materialDonationItems,
            }
        },

        async getRelatedAddresses() {
            const addresses = await Api.one(
                "companies",
                router.currentRoute.params.id
            )
                .all("addresses")
                .get({ include: "addressClassification" })
            this.$set(this.companyData, "addresses", addresses.data)
        },

        async getRelatedPhones() {
            const phones = await Api.one(
                "companies",
                router.currentRoute.params.id
            )
                .all("phones")
                .get({ include: "phoneClassification" })

            this.companyData = { ...this.companyData, phones: phones.data }
        },

        async getRelatedEmails() {
            const emails = await Api.one(
                "companies",
                router.currentRoute.params.id
            )
                .all("emails")
                .get()

            this.companyData = { ...this.companyData, emails: emails.data }
        },

        attachNote(notes) {
            const newPerson = _.cloneDeep(this.companyData)
            newPerson.notes = notes

            this.companyData = newPerson
        },

        async openEditCompanyPerson(person) {
            await this.$refs.companyPeopleList.openForm(person)
            this.$bvModal.show("attach-people-to-company-modal")
        },

        async getCompanyData(id) {
            const response = await Api.find("companies", id, {
                include:
                    "classifications,origin,status,relations,phones,emails,addresses,creator,editor,destroyer,notes," +
                    "businessSegment,correspondence,inactivationReason,newsletter,people,people.status,people.phones," +
                    "people.emails,addresses.addressClassification,notes.editor,notes.creator,phones.phoneClassification," +
                    "materialDonations,materialDonations.products,materialDonations.action,materialDonations.status," +
                    "materialDonations.contact,materialDonations.deliveryLocation,editor," +
                    "materialDonations.withdrawalAddress,materialDonations.products,materialDonations.products.category," +
                    "materialDonations.products.unitOfMeasurement",

                fields: {
                    "material-donations":
                        "observation,withdrawalDate,schedulingDate,materialDonationStatusReason" +
                        ",action,status,contact,withdrawalAddress,deliveryLocation,products",
                    "company-classifications": "description",
                    "company-relationships": "description",
                    status: "description",
                    origin: "description",
                    notes: "description,editor,creator",
                    emails: "address,isMainEmailAddress",
                    phones: "number,isMainPhoneNumber,phoneClassification",
                    correspondences: "description",
                    newsletters: "description",
                    users: "name",
                    "business-segments": "description",
                    addresses:
                        "postalCode,isMainAddress,number,complement,description,completeAddress,street,state," +
                        "locality,neighborhood,addressClassification",
                    actions: "title",
                    "material-donation-statuses":
                        "description,systemDefaultStatus",
                    products: "description,category,unitOfMeasurement",
                    "delivery-locations": "description",
                },
            })
            this.loading = false
            this.companyData = response.data
        },

        getClassifications() {
            return Api.findAll("company-classifications", {
                fields: { "company-classifications": "description" },
            })
        },

        getRelationships() {
            return Api.findAll("company-relationships", {
                fields: { "company-relationships": "description" },
            })
        },

        getStatus() {
            return Api.findAll("status", {
                fields: { status: "description" },
            })
        },

        getOrigins() {
            return Api.findAll("origin", {
                fields: { origin: "description" },
            })
        },

        getGenders() {
            return Api.findAll("genders", {
                fields: { genders: "description" },
            })
        },

        getSalutationsOptions() {
            return Api.findAll("salutations", {
                fields: { salutations: "description" },
            })
        },

        getSholaritiesOptions() {
            return Api.findAll("scholarities", {
                fields: { scholarities: "description" },
            })
        },

        getOrganizationsContactsOptions() {
            return Api.findAll("organizations-contacts", {
                fields: { "organizations-contacts": "description" },
            })
        },

        getCorrespondencesOptions() {
            return Api.findAll("correspondences", {
                fields: { correspondences: "description" },
            })
        },
        getBusinessSegmentsOptions() {
            return Api.findAll("business-segments", {
                fields: { correspondences: "description" },
            })
        },
        getNewslettersOptions() {
            return Api.findAll("newsletters", {
                fields: { newsletters: "description" },
            })
        },

        getPhoneClassificationsOptions() {
            return Api.findAll("phone-classifications", {
                fields: { "phone-classifications": "description" },
            })
        },

        getAddressClassificationsOptions() {
            return Api.findAll("address-classifications", {
                fields: { "address-classifications": "description" },
            })
        },

        getInactivationReasons() {
            return Api.findAll("inactivation-reasons", {
                fields: { "inactivation-reasons": "description" },
            })
        },

        getPeople(companyId) {
            return Api.findAll("companies-people", {
                filter: { companyId },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.profile-menu {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 5px;
    color: #6e6b7b !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.profile-menu-selected {
    background-color: #519bd6 !important;
    border-color: #519bd6 !important;
    border-radius: 5px;
    color: #ffffff !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.nav-tabs .nav-item .nav-link {
    font-size: 1rem;
    line-height: 1rem;
    color: #5e5873;
}

.pill-component {
    width: 99%;
    padding-right: 1rem;
}
</style>
