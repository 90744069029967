var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"pb-0 mb-0 pr-0 pl-0"},[_c('div',{staticClass:"d-block"},[_c('feather-icon',{attrs:{"icon":"UsersIcon","size":"28"}}),_c('h4',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t("modules.companies.mainPeople"))+" ")]),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.attach-people-to-company-modal",modifiers:{"attach-people-to-company-modal":true}}],staticClass:"under-blue-text",on:{"click":function($event){_vm.resourceEdited = null}}},[_vm._v(" + "+_vm._s(_vm.$tc("common.crud.attaching", 2))+" ")])],1),(_vm.resourceData)?_c('b-row',{staticClass:"mt-1"},[_vm._l((_vm.resourceData.people.filter(
                function (el) { return el.isPrimaryContact === true; }
            )),function(people,index){return _c('b-col',{key:index,staticClass:"mb-1",attrs:{"cols":"12"},on:{"mouseover":function($event){return _vm.showControls(index, 'companies-people')}}},[_c('div',{staticClass:"d-inline"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('a',{staticClass:"d-inline under-blue-text ml-1",on:{"click":function($event){return _vm.$router.push({
                            name: 'view-people',
                            params: { id: people.personId },
                        })},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.openNewTab(people)}}},[_vm._v(" "+_vm._s(people.name)+" ")])],1),(
                    _vm.selected.index === index &&
                    _vm.selected.resource === 'companies-people' &&
                    _vm.$can('update', 'companies')
                )?_c('feather-icon',{staticClass:"float-right under-blue-text d-inline align-middle",staticStyle:{"margin-right":"0.5rem"},attrs:{"icon":"Trash2Icon","size":"15"},on:{"click":function($event){return _vm.deleteCompanyPerson(people)}}}):_vm._e(),(
                    _vm.selected.index === index &&
                    _vm.selected.resource === 'companies-people' &&
                    _vm.$can('update', 'companies')
                )?_c('feather-icon',{staticClass:"float-right under-blue-text d-inline align-middle mr-1",attrs:{"icon":"Edit2Icon","size":"15"},on:{"click":function($event){return _vm.$emit('editCompanyPeople', people)}}}):_vm._e(),_c('div',{},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon","size":"18"}}),_c('p',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(people.jobDescription)+" ")])],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"a-link float-right text-right",on:{"click":function($event){return _vm.$emit('updateActiveTab', 7)}}},[_c('p',{staticClass:"under-blue-text d-inline"},[_vm._v(" "+_vm._s(_vm.$t("common.crud.seeAll"))+" ")])])])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }