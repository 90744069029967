var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.resourceData)?_c('attach-people-to-company',_vm._g({attrs:{"id":"attach-people-to-company-modal","resourceData":_vm.resourceData,"resourceType":_vm.resourceType,"recordEdit":_vm.recordEdit},on:{"hide":function($event){_vm.recordEdit = null}}},_vm.$listeners)):_vm._e(),_c('b-card',[_c('datatable-header',{attrs:{"title":_vm.$tc('modules.people.description', 2),"genericSearchEnabled":true,"searchTermPlaceHolder":_vm.$t('common.search'),"hideFilterButton":true},on:{"filterUpdated":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'attach-people-to-company-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.addNew", { resource: _vm.$tc( "modules.people.description", 1 ).toLowerCase(), }).toString())+" ")])]},proxy:true},{key:"advancedSearch",fn:function(ref){
                        var expanded = ref.expanded;
                        var onFilterChange = ref.onFilterChange;
return [_c('DatatableFilter',{attrs:{"availableDatatableFilters":_vm.availableDatatableFilters,"expanded":expanded},on:{"filterUpdated":_vm.onFilterUpdate,"onFilterChange":onFilterChange}})]}}])}),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: false,
            },"rows":_vm.resourceData.people,"rtl":_vm.direction,"searchOptions":{
                enabled: false,
                externalQuery: _vm.searchTerm,
            },"totalRows":_vm.resourceData.people.length,"theme":"my-theme"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'isPrimaryContact')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.isPrimaryContactVariant(
                                props.row.isPrimaryContact
                                    ? props.row.isPrimaryContact
                                    : false
                            )}},[_vm._v(" "+_vm._s(props.row.isPrimaryContact ? _vm.$t("common.primary") : _vm.$t("common.secondary"))+" ")])],1):(props.column.field === 'status')?_c('span',[(props.row.status)?_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status.description)}},[_vm._v(" "+_vm._s(props.row.status.description)+" ")]):_vm._e()],1):(props.column.field === 'emails')?_c('span',_vm._l((props.row.emails),function(email){return _c('span',{key:email.id},[_vm._v(" "+_vm._s(email.address)+" ")])}),0):(props.column.field === 'phones')?_c('span',_vm._l((props.row.phones),function(phone){return _c('span',{key:phone.id},[_vm._v(" "+_vm._s(phone.number)+" ")])}),0):(props.column.field === 'actions')?_c('span',[_c('span',{staticClass:"d-inline"},[_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.attach-people-to-company-modal",modifiers:{"attach-people-to-company-modal":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"Edit2Icon","size":"20"},on:{"click":function($event){return _vm.openForm(props.row)}}})],1),_c('span',{staticClass:"d-inline pl-1"},[_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"Trash2Icon","size":"20"},on:{"click":function($event){return _vm.onDeleteClick(props.row)}}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }