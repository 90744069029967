var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('update', 'companies'))?_c('b-modal',_vm._b({ref:"attach-people-to-company",attrs:{"centered":"","title":_vm.$tc('common.crud.attaching', 2) +
        ' ' +
        _vm.$tc('modules.people.description', 1).toLowerCase(),"hideFooter":"","size":"lg"},on:{"hide":function($event){return _vm.$emit('hide')},"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('FormulateForm',{attrs:{"name":"emailForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('formulateInput',{attrs:{"label":_vm.$tc('modules.people.description', 1),"placeholder":_vm.$t('common.crud.select'),"name":"personId","type":"vue-select-async","apiData":{
                        resource: 'people',
                        fields: { people: 'name' },
                        fieldsMapping: {
                            label: 'name',
                            value: 'id',
                        },
                    },"options":_vm.people,"validation":"required"},on:{"update:input":function($event){_vm.formData.personId = $event},"update:options":function($event){_vm.people = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.jobDescription'),"name":"jobDescription","placeholder":_vm.$t('modules.people.jobDescription'),"type":"text","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.primaryContact'),"name":"isPrimaryContact","placeholder":_vm.$t('common.crud.select'),"type":"select","options":{
                        true: _vm.$t('common.yes'),
                        false: _vm.$t('common.no'),
                    }}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.extension'),"name":"extension","placeholder":_vm.$t('modules.people.extension'),"type":"text","validation":"optional|extension","maxlength":"4","validationMessages":{
                        extension: _vm.$t(
                            'common.crud.errors.invalidExtension'
                        ),
                    }}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistent'),"name":"assistent","placeholder":_vm.$t('modules.people.assistent'),"type":"text","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentPhone'),"name":"assistentPhone","placeholder":_vm.$t('modules.people.assistentPhone'),"type":"text","maxlength":"11","validation":"optional|phone","validationMessages":{
                        phone: _vm.$t('common.crud.errors.invalidPhone'),
                    }}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentExtension'),"name":"assistentExtension","placeholder":_vm.$t('modules.people.assistentExtension'),"type":"text","maxlength":"4","validation":"optional|extension","validationMessages":{
                        extension: _vm.$t(
                            'common.crud.errors.invalidExtension'
                        ),
                    }}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentEmail'),"name":"assistentEmail","placeholder":_vm.$t('modules.people.assistentEmail'),"type":"text","validation":"optional|max:255,length|email"}})],1)],1),_c('b-row',[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateErrors')],1)],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center"},[(!_vm.recordEdit)?_c('FormulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$tc('common.crud.attaching', 1)
                        : _vm.$tc('common.crud.attaching', 2),"inputClass":"btn btn-primary","type":"submit"}}):_c('FormulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$t('common.crud.updating')
                        : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}}),_c('FormulateInput',{staticClass:"pl-2",attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hideModal}})],1)]}}],null,false,3078554069),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }